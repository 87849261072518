import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Gradient } from './lib/Gradient'

import { useRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { AsciiRenderer } from '@react-three/drei'
import * as THREE from 'three'
console.log(THREE)

function AlLWrapper({ hoover }) {
  return (
    <Canvas className="canvas">
      <All hoover={hoover} />
    </Canvas>
  )
}

let rotX = 0
let rotY = 0
let targetScale = 0
function All({ hoover }) {
  const ref = useRef()
  const [ascii, setAscii] = useState(false)
  const viewport = useThree((state) => state.viewport)
  useFrame((state, delta) => {
    rotY += 0.01
    ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, rotY, 0.02)
    ref.current.rotation.x = THREE.MathUtils.lerp(ref.current.rotation.x, rotX, 0.01)
    ref.current.scale.setScalar(THREE.MathUtils.lerp(ref.current.scale.x, (Math.min(viewport.width, viewport.height) / 5) * targetScale * (currOver === -1 ? 1 : 0), 0.1))
  })
  return (
    <>
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <ambientLight intensity={0.1} />
      <group scale={1.5}>
        <mesh
          onPointerOver={() => {
            setAscii(true)
            rotY += Math.PI
            rotX += Math.PI * 0.25
          }}
          onPointerOut={() => {
            setAscii(false)
            rotY += Math.PI
            rotX += Math.PI * 0.25
          }} scale={0} ref={ref}>
          <octahedronBufferGeometry args={[1, 0]} />
          <meshStandardMaterial color="green" />
        </mesh>
      </group>
      <color attach="background" args={[ascii ? 'black' : 'black']} />
      {
        (hoover) && <AsciiRenderer fgColor="#ffffff" characters=' .:-+*=%@#' bgColor="transparent" />
      }
    </>
  )
}
let currOver = -1

let timeout = null
export default function App() {
  const email = useRef();
  const body = useRef();
  const [overState, setOverState] = useState(-1)
  const [hooverState, setHooverState] = useState(false)
  const [contacted, setContacted] = useState(false)
  useLayoutEffect(() => {
    const gradient = new Gradient()
    gradient.initGradient('#gradient-canvas')
    document.getElementById('root').style.opacity = 1
    document.getElementById('komp').style.opacity = 1
    document.getElementById('top').style.opacity = 1
    document.getElementById('top-desc').style.opacity = 1


    setTimeout(() => {
      targetScale = 1
      rotY = 10
    }, 2500)

  }, [])
  let over = (inp) => {
    let o = (currOver === inp) ? -1 : inp
    currOver = o
    setOverState(o)
    clearTimeout(timeout)
  }

  return (
    <div id="main">
      <canvas id="gradient-canvas" data-transition-in />
      <div id="contentContainer">
        <div id="content">
          <div id="top" onClick={() => over(-1)}>
            <h2>SIXFOURS</h2>
            <p>[si fuʁ]</p>
          </div>
          <h1 id="top-desc" onClick={() => over(-1)}>Ett tech-bolag som erbjuder konsulttjänster inom:</h1>
          <div id='komp'>
            <div>
              <span className={overState === 0 ? 'active' : ''} onClick={() => over(0)} onMouseOver={() => setHooverState(true)} onMouseOut={() => setHooverState(false)}>
                Frontend
              </span>
            </div>
            <div>
              <span className={overState === 1 ? 'active' : ''} onClick={() => over(1)} onMouseOver={() => setHooverState(true)} onMouseOut={() => setHooverState(false)}>
                Backend
              </span>
            </div>
            <div>
              <span className={overState === 2 ? 'active' : ''} onClick={() => over(2)} onMouseOver={() => setHooverState(true)} onMouseOut={() => setHooverState(false)}>
                DevSecOps
              </span>
            </div>
            <div>
              <span className={overState === 3 ? 'active' : ''} onClick={() => over(3)} onMouseOver={() => setHooverState(true)} onMouseOut={() => setHooverState(false)}>
                Ledarskap
              </span>
            </div>
            <br />
            <div>
              <span className={overState === 4 ? 'active' : ''} onClick={() => over(4)} onMouseOver={() => setHooverState(true)} onMouseOut={() => setHooverState(false)}>
                Om oss
              </span>
            </div>
            <div>
              <span className={overState === 5 ? 'active' : ''} onClick={() => over(5)} onMouseOver={() => setHooverState(true)} onMouseOut={() => setHooverState(false)}>
                Att arbeta hos oss
              </span>
            </div>
            <div>
              <span className={overState === 6 ? 'active' : ''} onClick={() => over(6)} onMouseOver={() => setHooverState(true)} onMouseOut={() => setHooverState(false)}>
                Kontakta oss
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="canvasContainer">
        <AlLWrapper hoover={hooverState} />
      </div>
      <div id="inriktningar" className={'show'}>
        {overState === 0 && <span>
          Våra frontend-utvecklare är experter på tekniker som React och Vue och bygger snabba, responsiva, användarvänliga webbsidor och appar.
        </span>}
        {overState === 1 && <span>
          Våra backend-utvecklare är experter på serversidan av applikationen, med databaser, logik och affärsregler. Oftast tillsammans med frontend-utvecklare för att skapa helhetliga och högkvalitativa tjänster.
        </span>}
        {overState === 2 && <span>
          Med verktyg för continuous integration- och delivery hjälper våra DevSecOps att skapa processer och verktyg som stödjer team och organisationer i att utveckla och leverera kvalitativa produkter och tjänster.
        </span>}
        {overState === 3 && <span>
          Vi arbetar med agilt ledarskap som Scrum Masters, Product Owners, Operations Leads samt projektledare. Våra uppdrag inom ledarskap är oftast tekniskt inriktade.
        </span>}
        {overState === 4 && <span className="smaller">
          Vi är ett företag befriat från hierarkier och komplicerade lönemodeller där all energi går till att omsorgsfullt vårda en kultur där talanger trivs. Säkert, tydligt och personligt.<br /><br />
          För oss är det viktigt med socialt nätverkande och vi erbjuder en stor mängd möjligheter att träffas under spännande former för att lära känna sina kollegor och utbyta erfarenheter.<br /><br />
          Vi är idag 12 medarbetare med mycket god ekonomi samt starkt försäljningsnätverk vilket medför att vi ofta får förtroende att leda och deltaga i innovativa och framåtriktade uppdrag och projekt.
        </span>}
        {overState === 5 && <span className="smaller">
          Att arbeta hos oss innebär att man tar plats i en gemenskap med stor fokus på sociala aktiviteter och personlig utveckling. Genom att träffas skapar vi personliga band som gör det naturligt att kontakta sina kollegor när man behöver hjälp eller någon att stämma av tankar med.<br /><br />
          Du får samma fasta lön oavsett om du arbetar ute hos kund eller in-house. Vår inställning är att medarbetaren ska fokusera på att göra ett bra jobb, vara en god kollega och utvecklas. Det är företagets uppgift att ordna fram kunduppdragen och medarbetaren ska inte behöva känna en press i att jaga timmar eller känna oro över att inte veta hur hög lönen blir månad för månad.
        </span>}
        {overState === 6 && <div id="contact">
          <div id="form" className={contacted ? 'hide' : 'show'}>
            <input type="text" ref={email} placeholder='Din mailadress' />
            <textarea rows="3" ref={body} cols="50" placeholder='Meddelande'>
            </textarea>
            <div id="submit" onClick={() => {
              var from = email.current.value;
              var to = "kontakt@sixfours.se";
              var subject = "Kontaktförfråga: Hemsidan";
              var message = "Från: \n" + email.current.value + ' \n\nMeddelande: \n"' + body.current.value + '"';
              var xhr = new XMLHttpRequest();
              xhr.open("POST", "./mail.php", true);
              xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
              xhr.send("from=" + from + "&to=" + to + "&subject=" + subject + "&message=" + message);
              setContacted(true)
              timeout = setTimeout(() => {
                setContacted(false)
                setOverState(-1)
                currOver = -1
              }, 2500)
            }}>Kontakta oss</div>
          </div>
          <h4 className={!contacted ? 'hide' : 'show'}>Tack vi återkommer inom kort!</h4>
        </div>}
      </div>
    </div >
  )
}

